import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import 'gatsby-plugin-prismic-previews/dist/styles.css';

import './src/styles/global.css';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
);
